import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

const Template = ({ data, lang }) => {
  const { t } = useTranslation()
  return (
    <section id="sectionTemplate" className="template">
      <div className="template__container">
        <div className="row">
          <div className="col-12">
            <h2 className=" template__title">
              {t('page_home_section_3_title')}
            </h2>

            <p className=" h5-style secondary template__subtitle font-semibold">
              {t('page_home_section_3_descr')}
            </p>

            <div className="template__content">
              <div className="card-template">
                {data
                  .filter((model) => model.node.showcase === true)
                  .sort((a, b) => a.node.order - b.node.order)
                  .slice(0, 5)
                  .map((model, i) => {
                    return (
                      <div key={i} className="card-template_item">
                        <Link to={'/models/' + model.node.name.en}>
                          <img
                            src={model.node.miniature}
                            className="template-img"
                            alt={model.node.name.en}
                          />
                          {/* <h3 className="title">{model.node.name.en}</h3> */}
                        </Link>
                      </div>
                    )
                  })}
                <div className="card-template_item">
                  <div className="card-template_button">
                    <Link to={'/models'}>
                      <span>{t('page_home_section_3_list_link')}</span>
                    </Link>
                  </div>
                </div>
              </div>

              <p className="template__cta h4-style ">
                {t('page_home_section_3_cta')}
              </p>
              <div className="col-12 text-center">
                <Link
                  to={'/contact'}
                  className="step__btn step__btn--end btn btn-primary template__btn font-bold"
                >
                  {t('page_home_section_3_btn')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Template
